<template>
  <div class="group" :class="[size]">
    <a class="icon-search"></a>
    <input :placeholder="placeholder" @input="$emit('input', $event.target.value)">
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    size: String,
    placeholder: String
  }
}
</script>

<style scoped lang="scss">
  .group{
    width: calc(340px * 0.72);
    height: calc(36px * 0.75);
    background-color: rgba(174,174,174,0.20);
    border: 1px solid rgba(151,151,151,0.05);
    display: flex;
    align-items: center;


    @include for-desktop-up{
      width: 166px;
      height: 18px;
    }

    &.sm{
      width: 280px;

      @include for-desktop-up{
        width: 140px;
      }

      input{
        width: 213px;

        @include for-desktop-up{
          width: calc(213px / 2);
        }
      }
    }

    a{
      margin-left: 18px;

      @include for-desktop-up{
        margin-left: 9px;
        font-size: 7px;
        margin-top: -4px;
      }
    }

    input{
      margin-left: 15px;
      width: 273px;
      height: 36px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      font-size: calc(13px - 2px);
      margin-top: 0px;
      line-height: 19px;
      letter-spacing: 0;
      color: #4a4a4a;
      border: 0;
      outline: 0;
      background-color: transparent;

      @include for-desktop-devices{
        margin-top: 1px;
      }

      @include for-desktop-up{
        font-size: 7px;
        line-height: 10px;
        height: 18px;
        width: 136px;
        margin-left: 7px;
      }

      &::placeholder{
        font-weight: 100;
      }
    }

    input, select, textarea {
      color: #4a4a4a;
    }
  }


  .icon-search{
    font-size: 11px;
  }
</style>
