<template>
    <div class="user--view">
        <div class="navbar">
            <button class="back__button" @click="goToMainList"></button>
            <div class="user-info--container">
                <div class="account-info--container">
                    <div class="avatar--container">
                        <img :src="user.avatar">
                    </div>
                    <div class="name--container">
                        <p class="name__text">{{user.name}}</p>
                        <p class="category__text">{{user.typeName}}</p>
                    </div>
                </div>
                <div class="indicator--container">
                    <p><a class="icon-camera"></a>{{user.camerasCount}}</p>
                    <p><a class="icon-finance"></a>0</p>
                </div>
                <button class="circle__button" @click="goToSettings"><a class="icon-settings"></a></button>
            </div>
            <button class="btn btn__action" @click="goToCreateCamera">Добавить камеру</button>
        </div>
        <div class="user--container">
            <div class="camera--container">
                <div class="search--container">
                    <search-input  placeholder="Найти камеру" v-model="searchText"></search-input>
                </div>
                <div class="camera--list">
                    <div class="camera--list-item" v-for="camera of cameras" v-bind:key="camera.id" :class="{'selected' : camera.id === selectedCamera.id}" @click="selectCamera(camera)">
                        <p>{{camera.name}}</p>
                    </div>
                </div>
            </div>
            <div class="player--container" v-show="cameras.length > 0">
                <hls-player-new :uri="selectedCamera.uri" :is-show-share="false" :show-adding-controls="true" :name="selectedCamera.name" v-on:archive="onArchive"></hls-player-new>
              <img class="delete__btn" src="../../assets/icons/style-delete.svg" @click="showDeleteCameraModal">
            </div>
            <div class="pay--container">
                <payments></payments>
            </div>
        </div>
      <delete-camera-modal></delete-camera-modal>
    </div>
</template>

<script>
    import SearchInput from "@/components/input/SearchInput";
    import HlsPlayerNew from "@/components/player/HlsPlayerNew";
    import DeleteCameraModal from "@/components/modal/DeleteCameraModal";
    import Payments from "../../components/Payments";
    export default {
        name: "UserView",
        components: {Payments, DeleteCameraModal, HlsPlayerNew, SearchInput},
        data() {
            return {
                selectedCamera: {},
              searchText: ''
            }
        },
        computed: {
            user() {
                return this.$store.getters.SELECTED_USER
            },

          cameras(){
            const cameras = this.user.cameras
            if (cameras == null){
              return []
            }
            if (this.searchText.length === 0){
              return cameras
            }


            return cameras.filter(camera => camera.name.toLowerCase().includes(this.searchText.toLowerCase()))
          }
        },
        methods: {
            goToMainList() {
                this.$router.push({name: "MainView"})
            },
            goToCreateCamera(){
                this.$router.push({name: "CreateCameraView",params: {id : this.user.id}})
            },
            selectCamera(camera){
                this.selectedCamera = {...camera}
            },
          goToSettings(){
              this.$router.push({name: 'ChangeUserDataView',params: {id: this.user.id}})
          },

          async deleteCamera(){
            const userId = this.$route.params.id;
            await this.$store.dispatch("DELETE_CAMERA",{userId: userId,camera: this.selectedCamera});
            await this.$store.dispatch("GET_SELECTED_USER", {userId: userId})
            if (this.user.cameras.length > 0){
              this.selectCamera(this.user.cameras[0])
            }
          },


          showDeleteCameraModal(){
              this.$modal.show("DeleteCameraModal")
          },

          onArchive () {
            localStorage.setItem('camera', JSON.stringify(this.selectedCamera))
            this.$router.push({ name: 'Archive' })
          },
        },
        async mounted() {
            const userId = this.$route.params.id;
            await this.$store.dispatch("GET_SELECTED_USER", {userId: userId})
          if (this.user.cameras.length > 0){
            this.selectCamera(this.user.cameras[0])
          }
        }
    }


</script>

<style scoped lang="scss">
    .user--view{
        width: 100%;
        height: 100%;

        .navbar{
            display: flex;
            .back__button{
                border: 0;
                outline: 0;
                background-image: url("../../assets/icons/gradient.svg");
                /* Sketch doesnt export gradient borders at this point */
                box-shadow: 0 2px 2px 0 rgba(20,134,255,0.20);
                width: calc(72px * 0.66);
                height: calc(100px * 0.75);
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: cover;


                @include for-desktop-up{
                    width: 36px;
                    height: 50px;
                  box-shadow: 0 1px 1px 0 rgba(20, 134, 255, 0.20);

                }
                &:hover{
                  filter: brightness(87.5%);
                }
            }

            .user-info--container{
                width: calc(610px * 0.66);
                background: #ffffff;
                border: 1px solid #EBEBEB;
                height: calc(100px * 0.75);
                position: relative;

                @include for-desktop-up{
                    width: 305px;
                    height: 50px;
                }


                .circle__button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: calc(32px * 0.75);
                    right: calc(41px * 0.66);
                    position: absolute;
                    background: #7E72F2;
                    border-radius: 50%;
                    width: calc(36px * 0.75);
                    height: calc(36px * 0.75);
                    border: 0;
                    outline: 0;
                    cursor: pointer;

                    @include for-desktop-up{
                        width: 22px;
                        height: 22px;
                        top: 14px;
                        right: 16px;
                    }

                    &:hover{
                        filter: brightness(87.5%);
                    }

                    .icon-settings{
                      font-size: calc(18px - 4px);

                        @include for-desktop-up{
                            font-size: 11px;
                        }
                    }
                }



                .account-info--container{
                  margin-top: calc(18px * 0.75);
                  margin-left: calc(18px * 0.75);
                    display: flex;


                    @include for-desktop-up{
                        margin-top: 9px;
                        margin-left: 9px;
                    }

                    .avatar--container{
                      width: calc(37px * 0.75);
                      height: calc(37px * 0.75);
                        object-fit: cover;
                        border-radius: 50%;

                        @include for-desktop-up{
                            width: 19px;
                            height: 19px;
                        }

                        img{
                          width: calc(37px * 0.75);
                          height: calc(37px * 0.75);
                            object-fit: cover;
                            border-radius: 50%;

                            @include for-desktop-up{
                                width: 19px;
                                height: 19px;
                            }
                        }
                    }

                    .name--container{
                      margin-left: calc(26px * 0.75);

                        @include for-desktop-up{
                            margin-left: 12px;

                        }
                        .name__text{
                            font-family: Roboto, "sans-serif";
                          font-size: calc(14px - 2px);
                            color: #2C2C2C;
                            letter-spacing: 0;
                            margin-left: 0px;

                            @include for-desktop-up{
                                font-size: 7px;
                            }
                        }

                        .category__text{
                            font-family: Roboto, "sans-serif";
                          font-size: calc(12px - 2px);
                            color: #2C2C2C;
                            letter-spacing: 0;
                            margin-left: 0px;
                            margin-top: 7px;

                            @include for-desktop-up{
                                margin-top: 4px;
                                font-size: 6px;

                            }
                        }
                    }

                }

                .indicator--container{
                  margin-left: calc(18px * 0.75);
                  margin-top: calc(16px * 0.75);
                    display: flex;
                    align-items: center;


                    @include for-desktop-up{
                        margin-left: 9px;
                        margin-top: 7px;

                    }


                    .icon-finance{
                        font-size: 10px;

                        @include for-desktop-up{
                            font-size: 6px;
                        }
                    }

                    .icon-acc{
                        font-size: 14px;

                        @include for-desktop-up{
                            font-size: 8px;
                        }
                    }

                    .icon-camera{
                        font-size: 10px;

                        @include for-desktop-up{
                            font-size: 6px;
                        }
                    }

                    a{
                        margin-right: 10px;

                        @include for-desktop-up{
                            margin-right: 5px;
                        }
                    }

                    p{
                        opacity: 0.9;
                        font-family: Roboto, "sans-serif";
                        font-size: 12px;
                        color: #525252;
                        letter-spacing: 0.54px;
                        margin-left: 20px;

                        @include for-desktop-up{
                            margin-left: 10px;
                            font-size: 6px;

                        }

                    }

                    p:first-child{
                        margin-left: 0;
                    }
                }

            }

            .btn{
                width: 100px;
                height: 21px;
                background: #6F64F8;
                border-radius: 4px;
                font-family: Roboto, "sans-serif";
                font-size: 8px;
                letter-spacing: 0;
                border: 0;
                outline: 0;
                cursor: pointer;

                @include for-big-desktop-up{
                    width: calc(278px * 0.66);
                    height: calc(56px * 0.75);
                    border-radius: 6px;
                    font-size: calc(16px - 4px);
                    letter-spacing: 0;
                    margin-left: calc(49px * 0.75);
                    margin-top: calc(23px * 0.75);
                }

                @include for-desktop-up{
                    width: 160px;
                    height: 26px;
                    border-radius: 4px;
                    font-family: Roboto, "sans-serif";
                    font-size: 11px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    margin-left: 25px;
                    margin-top: 12px;
                }

                &:hover{
                    filter: brightness(87.5%);
                }

                &.not_available{
                    pointer-events: none;
                    opacity: .5;
                }
            }

            .btn__action{
                background: #7E72F2;
                color: #FFFFFF;
            }

            .btn__img{
                background: #7E72F2;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;

                @include for-big-desktop-up{
                    width: 200px;
                    height: 48px;
                }

                img{
                    margin-right: 23px;

                }

            }

            .btn__close{
                background: #EDEDED;
                color: #2C2C2C;
                margin-right: 15px;

                @include for-big-desktop-up{
                    margin-right: 30px;
                }
            }
        }

        .user--container{
            margin-top: 71px;
            display: flex;

            @include for-desktop-up{
                margin-top: 36px;
            }

            .camera--container{
                width: calc(375px * 0.66);
                height: calc(589px * 0.75);
                background: #F9F9F9;
                border: 2px solid #EBEBEB;
                border-radius: 8px;
                margin-left: 72px;

                @include for-desktop-up{
                    margin-left: 36px;
                    width: 170px;
                    height: 294px;
                }


                .search--container{
                    margin-top: 23px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 340px;

                }

                .camera--list{
                    margin-top: 20px;
                    width: calc(375px * 0.66);
                    height: calc(426px * 0.75);
                    overflow: hidden;
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;


                  @include for-desktop-up{
                    width: 170px;
                    height: 213px;
                    margin-top: 10px;
                  }



                    .camera--list-item{
                        width: calc(371px * 0.66);
                        height: calc(52px * 0.75);
                        display: flex;
                        border-bottom: 1px solid rgba(139,138,141,.20);
                        align-items: center;
                        cursor: pointer;

                      @include for-desktop-up{
                        width: 166px;
                        height: 26px;
                      }

                        p{
                            font-family: Roboto, "sans-serif";
                            font-size: calc(17px - 4px);
                            letter-spacing: 0;
                            line-height: 20px;
                            color: #8B8A8D;
                            margin-left: 19px;

                          @include for-desktop-up{
                            margin-left: 9px;
                            font-size: 8px;
                          }
                        }

                        .separator{
                            width: 1px;
                            height: 30px;
                            border-left: 1px solid rgba(139,138,141,.2);
                            margin-left: 16px;


                          @include for-desktop-up{
                            height: 15px;
                            margin-left: 8px;

                          }
                        }

                        .class-name__text{
                            font-weight: 400;
                            width: 70px;
                            margin-left: 19px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                        }

                        &:hover{
                            background-color: rgba(0,0,0,0.05);
                        }

                        &:last-child{
                            margin-bottom: 33px;
                        }
                    }
                    .camera--list-item.selected{
                        p{
                            color: #FD5739;
                        }
                        &:hover{
                            background-color: transparent;
                        }
                    }
                }
            }

            .player--container{
                width: calc(784px * 0.66);
                height: calc(553px * 0.75);
                margin-left: 40px;
              position: relative;

              @include for-desktop-up{
                width: 390px;
                height: 270px;
                margin-left: 20px;
              }
            }


            .pay--container{
                width: calc(371px * 0.66);
                //height: calc(410px * 0.75);
                min-height: calc(410px * 0.75);
                background: #FFFFFF;
                min-width: 320px;
                box-shadow: 0 2px 8px 0 rgba(0,0,0,0.14);
                border-radius: 8px;
                margin-left: 40px;
                margin-top: calc(88px * 0.75);



              @include for-desktop-up{
                transform: translate(-10%, -20%) scale(.6);
                //width: 186px;
                  min-width: auto;
                //height: 205px;
                border-radius: 4px;
                margin-left: 20px;
                box-shadow: 0 1px 4px 0 rgba(0,0,0,0.14);
                margin-top: 44px;

              }
            }
        }
    }

    .delete__btn{
      position: absolute;
      top: 0;
      right: 0;

      cursor: pointer;

      &:hover{
        filter: brightness(95%);
      }

      @include for-desktop-up{
        width: 40px;
        height: 20px;
        right: -40px;
        top: 3px;

      }

      @include for-big-desktop-up{
        width: calc(78px * 0.66);
        height: calc(37px * 0.75);
        right: calc(-78px * 0.66);
        top: 10px;
      }
    }
</style>
