<template>
  <div class="hls--container" @mouseover.capture="showControls" @mouseleave.capture="hideControls">
    <video ref="hlsVideo"  autoplay muted playsinline @click.self="showControls($event)"> </video>
    <div class="loader" v-if="isLoading">
      <video-loader></video-loader>
    </div>
<!--    <img class="volume-off&#45;&#45;image" src="../../assets/icons/volume-off.svg" @click="toggleMute" v-if="isMuted" :class="{'show' : isShowControls}">-->
<!--    <img class="volume-on&#45;&#45;image" src="../../assets/icons/volume-on.svg" @click="toggleMute" v-else :class="{'show' : isShowControls}">-->
    <div class="audio--container" v-if="isAudio === true">
      <a class="icon icon-mute-1 volume-off--image" @click="toggleMute" v-if="isMuted" :class="{'show' : isShowControls}"></a>
      <a class="icon icon-loud volume-on--image" @click="toggleMute" v-else :class="{'show' : isShowControls}"></a>
    </div>
    <div class="audio--container no-audio" v-if="isAudio === false">
      <p>Без звука</p>
    </div>

    <img class="archive--image" src="../../assets/icons/archive.svg" @click="$emit('archive')" :class="{'show' : isShowControls && showAddingControls,'right' : !isShowShare}">
    <img class="fullscreen--image" src="../../assets/icons/fullscreen.svg" @click="gotofullsize" :class="{'show' : isShowControls }">
    <img class="share__img" src="../../assets/icons/share.svg" @click="onShare" :class="{'show' : isShowControls && showAddingControls && isShowShare}">
    <p class="camera__text" :class="{'show' : isShowControls}">{{name}}</p>
  </div>
</template>

<script>
import * as Hls from 'hls.js'
import VideoLoader from '@/components/loader/VideoLoader'

export default {
  name: 'HlsPlayerNew',
  components: { VideoLoader },
  props: {
    uri: String,
    name: String,
    showAddingControls: Boolean,
    isShowShare: Boolean
  },
  data () {
    return {
      hlsPlayer: null,
      isLoading: false,
      isMuted: true,
      isShowControls: false,
      isAudio: null
    }
  },
  watch: {
    isMuted: {
      handler (newVal) {
        const video = this.$refs.hlsVideo
        console.log('setvideo', video, newVal)
        if (video != null) {
          video.muted = newVal
        }
      }
    },
    uri: {
      handler (newVal) {
        if (newVal != null && newVal.length > 0) {
          this.playVideo()
        }
      }
    }
  },
  computed: {
    isMobile () {
      return screen.width < 992
    }
  },
  methods: {
    showControls (el) {
      // console.log(el.target.tagName)
      // if (el == null || el.target.tagName === 'VIDEO') {
      //   console.log('show controls', this.isShowControls)
      //   if (this.isShowControls) {
      //     this.hideControls()
      //   } else {
      //     this.isShowControls = true
      //   }
      // }
      if ((this.isMobile && el.type === 'click') || (!this.isMobile && (el.type === 'mouseover' || el.type === 'mouseleave'))) {
        if (this.isShowControls) {
          this.hideControls()
        } else {
          this.isShowControls = true
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    hideControls (el) {
      this.isShowControls = false
    },
    gotofullsize () {
      const e = this.$refs.hlsVideo
      if (e.requestFullscreen) {
        e.requestFullscreen()
      } else if (e.mozRequestFullScreen) {
        e.mozRequestFullScreen()
      } else if (e.webkitRequestFullscreen) {
        e.webkitRequestFullscreen()
      } else if (e.msRequestFullscreen) {
        e.msRequestFullscreen()
      } else {
        e.webkitSetPresentationMode('fullscreen')
        console.log('not work why')
      }
    },

    playVideo () {
      if (this.hlsPlayer != null) {
        console.log(this.hlsPlayer)
        this.hlsPlayer.detachMedia()
      } else {
        // @ts-ignore
        this.hlsPlayer = new Hls()
      }
      // @ts-ignore
      const video = this.$refs.hlsVideo
      video.volume = 1.0
      video.pause()
      // @ts-ignore
      if (Hls.isSupported()) {
        this.hlsPlayer.loadSource(this.uri)
        this.hlsPlayer.attachMedia(video)
        this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, (typeEvent,data1) => {
          console.log(video)
          video.play()
          console.log(data1)
          this.isAudio = data1.audio
        })
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.uri
        video.addEventListener('loadedmetadata', () => {
          console.log(video)
          video.play()
        })
      }

      video.addEventListener('waiting', () => {
        this.isLoading = true
      }, false)

      video.addEventListener('playing', () => {
        this.isLoading = false
      }, false)

      video.addEventListener('loadstart', (e) => {
        if (e.target.paused) {
          this.isLoading = false
        } else {
          this.isLoading = true
        }
      }, false)

      video.addEventListener('error', () => {
        this.isLoading = false
      }, false)

      video.addEventListener('timeupdate', () => {
        this.isLoading = false
      }, false)

      video.addEventListener('webkitendfullscreen', () => {
        setTimeout(() => { video.play() }, 1500)
      }, false)


    },

    stopVideo () {
      if (this.hlsPlayer != null) {
        console.log(this.hlsPlayer)
        this.hlsPlayer.detachMedia()
      }
    },

    toggleMute () {
      this.isMuted = !this.isMuted
    },

    onShare () {
      this.$emit('share')
    }
  }

}

</script>

<style scoped lang="scss">

  .hls--container{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  video{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: black ;
    position: absolute;
    left: 0;
    top:0;
  }

  button{
    position: absolute;
    left: 0;
    top: 0;
  }

  .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  .volume-off--image{
    width: 12px;
    height: 12px;
    font-size: 12px;
    cursor: pointer;

  }

  img{
    cursor: pointer;
    opacity: 0;
  }

  img:hover{
    filter: brightness(80%);
  }

  .volume-on--image{
    width: 20px;
    height: 20px;
    font-size: 20px;

    cursor: pointer;
    margin-left: -5px;
  }

  .fullscreen--image{
    width: 20px;
    height: 18px;
    position: absolute;
    bottom: 20px;
    right: 30px;
  }

  .archive--image{
    width: 24px;
    height: 15px;
    position: absolute;
    top: 16px;
    right: 60px;
    z-index: 11;


    &.right{
      right: 22px;
    }
  }

  .camera__text{
    font-size: 14px;
    font-family: Roboto, "sans-serif";
    font-weight: 100;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    left: 18px;
    top: 19px;
    color: white;
    opacity: 0;
  }

  .share__img{
    position: absolute;
    right: 22px;
    top: 16px;
    width: 19px;
    height: 17px;

    z-index: 10;

  }

  a{
    opacity: 0;
  }

  .show{
    opacity: 1;
  }

  img{
    overflow: hidden;
  }


  .audio--container{
    position: absolute;
    bottom: 18px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .no-audio{
    left: 0px;

    background-color: transparent;
    border-radius: 50%;
    width: 91px;
    height: 28px;



    p{
      font-family: Roboto, "sans-serif";
      font-weight: 100;
      font-size: 13px;
      width: 91px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      /*background-color: rgba(0,0,0,.65);*/
      border-radius: 14px;
      letter-spacing: 0.34px;
      transition: all 0.3s;
      color: white;

      @include for-desktop-up{
        font-size: 11px;
      }

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    }
  }
</style>
